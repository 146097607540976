<template>
  <div id="payrollAdvance" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">ANTICIPO DE NÓMINA</v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Razón Social</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              :disabled="comercial"
              @change="anticipo()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="payrollAdvance"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Anticipos por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombre }}</td>
                  <td class="text-sm-center">{{ item.monto }}</td>
                  <td class="text-sm-center">{{ item.fechaAnticipo }}</td>
                  <td class="text-sm-center">{{ item.totalDescuentoMonto }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row no-gutters class="titulo ml-10" justify="center">
        <v-col cols="12" lg="6" md="6" sm="6" class="mt-4">
          <button
            class="botonAmarillo mt-5"
            style="font-size: 0.9rem"
            @click="descargarExcel()"
          >
            Descargar excel
          </button>
          <button
            class="botonAmarilloDerecho"
            style="font-size: 0.9rem"
            @click="confirmarNomina()"
          >
            Enviar a Nómina
          </button>
        </v-col>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">¿Seguro de envíar a nómina?</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="enviarNomina()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N° de Registro",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de Empleado",
          align: "center",
          value: "nombre",
        },
        {
          text: "Monto",
          align: "center",
          value: "monto",
        },
        {
          text: "Fecha de Anticipo",
          align: "center",
          value: "fechaAnticipo",
        },
        {
          text: "Total de descuento",
          align: "center",
          value: "totalDescuentoMonto",
        },
      ],
      payrollAdvance: [],
      enterprises: [],
      payrollAdvanceSelec: [],
      enterprise: localStorage.empresaIdGlobal,
      respuesta: "",
      advice: false,
      id: "",
      date: "",
      total: "",
      edit: false,
      vacio: false,
      comercial: false,
      employees: [],
      anticiposEmpleado: [],
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y espacio
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    format_date() {
      //console.log("fecha pasada"+this.fecha);
      this.date = this.date.substr(0, 10);
      //console.log("fecha obtenida"+this.fecha);
      return this.date;
    },
    obtenerEmpresa() {
      /* console.log("el id de empresa es" + this.enterprise);
      return (localStorage.idEmpresa = this.enterprise); */
      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    descargarExcel() {
      this.show = true;
      if (this.payrollAdvance.length > 0) {
        axios
          .get(
            Server +
              "/anticiposNominaEmpleado/reporte/" +
              this.enterprise +
              ",false",
            {
              responseType: "blob",
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            var fecha = new Date();
            link.setAttribute(
              "download",
              "AnticiposNomina" +
                fecha.getMonth() +
                "_" +
                fecha.getFullYear() +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.show = false;
          })
          .catch((e) => {
            //console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        this.show = false;
      }
    },
    /*Se da la estructura correcta para el envio a api */
    enviar() {
      console.log("ordenar lo siguiente", this.payrollAdvance);
      //var aux=[];
      this.payrollAdvance.forEach((element) => {
        this.anticiposEmpleado.push({
          empleadoId: element.empleadoId,
          anticiposId: [],
        });
      });
      let set = new Set(this.anticiposEmpleado.map(JSON.stringify));
      this.anticiposEmpleado = Array.from(set).map(JSON.parse);
      //console.log("empleados sin repetir",this.anticiposEmpleado);
      this.anticiposEmpleado.forEach((value, index) => {
        this.payrollAdvance.forEach((element) => {
          if (value.empleadoId == element.empleadoId) {
            var indice = this.anticiposEmpleado.indexOf(value);
            this.anticiposEmpleado[indice].anticiposId.push(element.id);
          }
        });
      });
      console.log("objeto que necesita francisco", this.anticiposEmpleado);
    },
    enviarNomina() {
      var nominaTot = this.payrollAdvance.length - 1;
      console.log("nominaTot", nominaTot);
      this.enviar();
      this.payrollAdvance.forEach((element, index) => {
        this.show = true;
        console.log("index", index);
        axios
          .put(
            Server + "/anticiposNominaEmpleado/" + element.id,
            {
              Id: element.id,
              EmpleadoId: element.empleadoId,
              Monto: element.monto,
              TotalDescuentoMonto: element.totalDescuentoMonto,
              FechaAnticipo: element.fechaAnticipo,
              Status: true,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log("actualizado:", element.id);
            this.payrollAdvance = [];
            this.advice = false;
            this.show = false;
            if (nominaTot == index) {
              console.log("nominaTot", nominaTot);
              console.log("index", index);
              this.enviaNotificaciones();
            }
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      });
    },
    confirmarNomina() {
      if (this.payrollAdvance.length > 0) {
        this.advice = true;
      }
    },
    enviaNotificaciones() {
      this.show = true;
      axios
        .post(
          Server + "/anticiposNominaEmpleado/envio-notificaciones",
          this.anticiposEmpleado,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.show = false;
          this.anticipo()          
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    anticipo() {
      this.show = true;
      axios
        .get(Server + "/anticiposNominaEmpleado/por-empresa/"+this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var anticipos = response.data;
          anticipos.forEach((value, index) => {
            if (value.status == false) {
              this.date = value.fechaAnticipo;
              this.format_date();
              this.payrollAdvance.push({
                id: value.id,
                monto: value.monto,
                nombre:
                  value.empleado.nombre +
                  " " +
                  value.empleado.apellidoMaterno +
                  " " +
                  value.empleado.apellidoPaterno,
                totalDescuentoMonto: value.totalDescuentoMonto,
                fechaAnticipo: value.fechaAnticipo, //this.date,
                empleadoId: value.empleadoId,
                status: value.status,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.anticipo();
  },
};
</script>